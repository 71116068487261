import React from 'react';
import { shape, string } from 'prop-types';
import { PillsBuilder } from '@ui-components-web/ui-pills-builder';

const PillBuilder = ({ pills, class_name: className }) => (
  <>{pills?.length > 0 && <PillsBuilder pills={pills} cssClass={className} />}</>
);

PillBuilder.propTypes = {
  class_name: string,
  pills: shape({}),
};

PillBuilder.defaultProps = {
  class_name: '',
  pills: [],
};

export default PillBuilder;
