import modalMapper from './modal';
import budgetMapper from './budget';
import contactMapper from './contact';
import quoteModal from './quote-modal';
import questionsMapper from './questions';
import actionModalMapper from './action-modal';
import reQuotationMapper from './re-quotation';
import questionsModalMapper from './questions-modal';
import multifamilyLTRMapper from './multifamily-LTR';
import fetchReQuotationMapper from './fetch-re-quotation';
import createReQuotationMapper from './create-re-quotation';
import questionsUnregisteredMapper from './questions-unregistered';

const visMappers = {
  ...quoteModal,
  ...modalMapper,
  ...budgetMapper,
  ...contactMapper,
  ...questionsMapper,
  ...actionModalMapper,
  ...reQuotationMapper,
  ...fetchReQuotationMapper,
  ...createReQuotationMapper,
  ...questionsModalMapper,
  ...multifamilyLTRMapper,
  ...questionsUnregisteredMapper,
};

export default visMappers;
