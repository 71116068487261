import { string } from 'prop-types';

const HeaderFallback = ({ componentId, title }) => {
  if (!title) {
    throw new Error(
      `Catch on vpp-boundary (In fallback step). Error on ${componentId} component. Description: Missing Title for HeaderFallback`,
    );
  }
  return <h1 className="ui-pdp-title ui-pdp-title--fallback-boundary">{title}</h1>;
};

HeaderFallback.propTypes = {
  title: string.isRequired,
  componentId: string.isRequired,
};

export default HeaderFallback;
