import React from 'react';
import { string } from 'prop-types';
import Script from 'nordic/script';
import StoreHeader from '../../features/store-header/store-header';
import Gallery from '../../features/gallery/gallery.desktop';
import Onboarding from '../../features/onboarding/onboarding.desktop';
import CPG from '../../features/cpg/header-cpg.desktop';

// Head Section
import RelatedSearches from '../../features/related-searches';

import ItemStatusHead from '../../features/item-status/head';
import ItemStatusHeadCompats from '../../features/item-status/head.compats.desktop';
import RedirectFromStatus from '../../features/item-status/redirected-from';
import GroupedHeader from './sections/grouped-header';
import CompatsWidgetSticky from '../../features/compats-widget-sticky/compats-widget-sticky.desktop';
import CompatsWidget from '../../features/compats-widget/compats-widget.desktop';
import RecommendationsListHorizontal from '../../features/recommendations/list-horizontal';

// Short Description
import Header from '../../features/header/header.desktop';

import Price from '../../features/price/price.desktop';
import PriceExtras from '../../features/price-extras/price-extras.desktop';
import Promotions from '../../features/promotions/promotions';
import PaymentSummary from '../../features/payment-summary/payment-summary.desktop';
import CpgSummary from '../../features/cpg/summary.desktop';
import ShippingSummary from '../../features/shipping-summary/shipping-summary.desktop';
import MoqInformation from '../../features/moq-information/moq-information.desktop';
import StickyWrapper from '../../features/sticky-wrapper';
import SecondShippingSummary from '../../features/second-shipping-summary/second-shipping-summary.desktop';
import PickUpSummary from '../../features/pick-up-summary/pick-up-summary.desktop';
import ReturnsSummary from '../../features/returns-summary/returns-summary.desktop';
import Variations from '../../features/variations/variations.desktop';
import ItemStatusShortDescription from '../../features/item-status/short-description';
import Seller from '../../features/seller/seller.desktop';
import StockInformation from '../../features/stock-information/stock-information.desktop';
import PaypalTooltip from '../../features/tooltips/paypal-tooltip';
import AvailableQuantity from '../../features/available-quantity/available-quantity.desktop';
import MainActions from '../../features/main-actions/main-actions.desktop';
import CartActions from '../../features/cart-actions/cart-actions.desktop';
import CreditsTooltip from '../../features/tooltips/credits-tooltip';
import ShippingCartBenefitsTooltip from '../../features/tooltips/shippin-cart-benefits-tooltip';
import LoyaltyTooltip from '../../features/tooltips/loyalty-tooltip';
import BuyBenefits from '../../features/buy-benefits/buy-benefits.desktop';

// Content Left
import RecommendationsCombos from '../../../../components/combos/combos.desktop';

import Compatibilities from '../../features/compatibilities/compatibilities.desktop';
import TechnicalSpecifications from '../../features/technical-specifications/technical-specifications.desktop';
import Description from '../../features/description/description.desktop';
import ApparelSizeSpecsPreview from '../../features/apparel-size-specs-preview/apparel-size-specs-preview.desktop';
import QADB from '../../features/qadb';
import Reviews from '../../features/reviews/reviews.desktop';
import ReviewsCapability from '../../../../components/reviews-capability/reviews.desktop';
import HighlightedSpecsAttrs from '../../features/highlighted-specs/highlighted-specs-attrs.desktop';
import Pills from '../../features/pills';
import ThirdPartyFirstSection from '../../features/third_party_first_section';
import ThirdPartySecondSection from '../../features/third_party_second_section';
import Grid from '../../../../components/grid';

// Recommendations
import RecommendationsCarouselSnapped from '../../features/recommendations/carousel-snapped';
import RecommendationsCarouselSnappedHorizontal from '../../features/recommendations/carousel-snapped-horizontal';

// General
import View from '../view';

import OnDemandIframe from '../../features/on-demand-iframe/on-demand-iframe';

const CoreDesktop = props => {
  const { siteId } = props;
  return (
    <View {...props} vertical="mshops">
      <OnDemandIframe />
      <Onboarding />
      <CPG />
      <Grid.Container className="ui-pdp-container--top shops-pdp-container--top">
        <Grid.Row>
          <Grid.Col className="ui-vip-core-container--head shops-vip-head">
            <RelatedSearches />
            <RedirectFromStatus />
            <ItemStatusHead />
            <RecommendationsListHorizontal
              featureName="carousel_cheaper"
              className="pt-12 pb-4 pl-0 pr-0"
              lazy={false}
              preload
              fetchPriority="high"
              criticalImages={3}
            />
            <RecommendationsCarouselSnapped
              featureName="carousel_v2p_not_active_item"
              className="mb-24 mt-24"
              lazy={false}
              preload
              fetchPriority="high"
              criticalImages={5}
            />
            <GroupedHeader />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <Grid.Container>
        <StoreHeader />
      </Grid.Container>
      <CompatsWidgetSticky />
      <ItemStatusHeadCompats />
      <Grid.Container className="ui-pdp-container--pdp shops-pdp-container">
        <Grid.Row id="ui-pdp-main-container" className="ui-pdp-container__row--reverse ui-pdp--relative  pb-40">
          <Grid.Col className="ui-pdp-container--column-right mt-16 pr-16 ui-pdp--relative shops-pdp-container--column-right">
            <StickyWrapper columnName="right">
              <Grid.Row className="ui-pdp-component-list pr-16 pl-16 shops-pdp-component-list">
                <Grid.Col
                  size={2}
                  className="ui-vip-core-container--short-description ui-vip-core-container--column__right shops-vip-short-description"
                >
                  <Header />
                  <Price />
                  <Promotions />
                  <PaymentSummary />
                  <CpgSummary />
                  <ShippingSummary />
                  <SecondShippingSummary />
                  <PickUpSummary />
                  <ReturnsSummary />
                  <Variations />
                  <ThirdPartyFirstSection />
                  <ItemStatusShortDescription />
                  <Seller />
                  <CompatsWidget />
                  <div className="ui-pdp-container__row--stock-and-full">
                    <StockInformation />
                  </div>
                  <PriceExtras />
                  <AvailableQuantity />
                  <MoqInformation />
                  <PaypalTooltip />
                  <MainActions />
                  <CartActions />
                  <CreditsTooltip />
                  <ShippingCartBenefitsTooltip />
                  <LoyaltyTooltip />
                  <BuyBenefits />
                </Grid.Col>
              </Grid.Row>
            </StickyWrapper>
          </Grid.Col>
          <Grid.Col className="ui-pdp-container--column-left pb-40 shops-pdp-container--column-left" size={2}>
            <Grid.Row>
              <Grid.Col size={2}>
                <Pills />
                <Gallery />
              </Grid.Col>
            </Grid.Row>
            <Grid.Col className="ui-vip-core-container--content-left">
              <RecommendationsCombos className="ui-pdp-combos" />
              <RecommendationsCarouselSnapped
                featureName="carousel_up_web"
                className="ui-pdp-carousel-snapped-up"
                slidesPerView={3}
              />
              <RecommendationsCarouselSnappedHorizontal
                featureName="carousel_above_swap"
                className="ui-pdp-carousel-snapped-up vip"
                slidesPerView={2}
                arrowsSize="large"
              />
              <RecommendationsCarouselSnapped
                featureName="carousel_above"
                className="ui-pdp-carousel-snapped-up"
                slidesPerView={3}
              />
              <Compatibilities />
              <TechnicalSpecifications />
              <ApparelSizeSpecsPreview />
              <HighlightedSpecsAttrs />
              <Description />
              <ThirdPartySecondSection />
              <QADB />
              <Grid.Col size={3}>
                <Reviews />
                <ReviewsCapability />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      {siteId === 'MLB' && <Script src="block-extensions.js" inline />}
    </View>
  );
};

CoreDesktop.propTypes = {
  siteId: string.isRequired,
};

export default CoreDesktop;
