import DEFAULT_CONFIG from '../config/default-config';
import priceMapper from '../mappers/price-mapper';

const priceConfig = {
  ...DEFAULT_CONFIG,
  isCritic: true,
  hasFallback: false,
  mapComponentInfoToProps: priceMapper,
};

export default priceConfig;
