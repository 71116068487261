import { useMemo, useState, useContext } from 'react';
import StaticPropsContext from '../../context/static-props';
import setZoomLeftPosition from '../utils/set-zoom-left-position';

const useZoomOptions = (zoomRef, namespace) => {
  const { layout } = useContext(StaticPropsContext);
  const [galleryOptions, setGalleryOptions] = useState({
    top: 0,
    left: 0,
  });

  const visibleClass = `${namespace}__zoom-container--visible`;

  const zoomOptions = useMemo(
    () => ({
      namespace: `${namespace}__zoom`,
      onShow: () => {
        const zoom = zoomRef.current;
        const firstThumbnail = document
          .querySelector('.ui-pdp-gallery__thumbnail:first-of-type')
          .getBoundingClientRect();

        setGalleryOptions({
          top: firstThumbnail.top,
          left: setZoomLeftPosition({ left: firstThumbnail.left, layout }),
        });
        document.body.appendChild(zoom);

        if (zoom) {
          zoom.classList.add(visibleClass);
        }
      },
      onHide: () => {
        if (zoomRef.current) {
          zoomRef.current.classList.remove(visibleClass);
        }
      },
    }),
    [zoomRef, layout, namespace, visibleClass],
  );

  return { zoomOptions, galleryOptions };
};

export default useZoomOptions;
