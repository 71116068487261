import { FETCH_START, FETCH_ERROR, FETCH_COMPLETE, FETCH_END } from '../../../../actions/vip/types';

const fetchStart = state => ({ ...state, isFetching: true, error: null });

const fetchError = (state, action) => ({ ...state, isFetching: false, error: action.error });

const fetchComplete = (state, action) => ({
  ...state,
  id: action.payload.id,
  pathName: action.payload.pathName,
  parentUrl: action.payload.parentUrl,
  attributes: action.payload.attributes,
  variation_id: action.payload.variation_id || null,
  questions: action.payload.questions,
  components: {
    ...state.components,
    ...action.payload.components,
    ...(state.components.compats_feedback
      ? {
          compats_feedback: {
            ...state.components.compats_feedback,
          },
        }
      : {}),
    ...(state.components.technical_specifications
      ? {
          technical_specifications: {
            ...state.components.technical_specifications,
          },
        }
      : {}),
    ...(state.components.highlighted_specs_attrs
      ? {
          highlighted_specs_attrs: {
            ...state.components.highlighted_specs_attrs,
          },
        }
      : {}),
    ...(state.components.highlighted_specs_attrs_swap
      ? {
          highlighted_specs_attrs_swap: {
            ...state.components.highlighted_specs_attrs_swap,
          },
        }
      : {}),
    ...(state.components.highlighted_specs_attrs_third_swap
      ? {
          highlighted_specs_attrs_third_swap: {
            ...state.components.highlighted_specs_attrs_third_swap,
          },
        }
      : {}),
  },
  track: action.payload.track,
  preload: action.payload.preload,
  isFetching: false,
  error: null,
});

const fetchEnd = state => ({ ...state, isFetching: false, error: null });

const fetchStatusMapper = {
  [FETCH_START]: fetchStart,
  [FETCH_ERROR]: fetchError,
  [FETCH_COMPLETE]: fetchComplete,
  [FETCH_END]: fetchEnd,
};

export { fetchStart, fetchError, fetchComplete };
export default fetchStatusMapper;
