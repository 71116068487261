import boundaryConfigMap from './config/config-map';
import ownershipMap from './config/ownership-map';
import criticComponents from './config/critic-components';
import DEFAULT_CONFIG from './config/default-config';
import fallbackComponentsMap from './components/fallback-components-map';

const getBoundaryConfig = ({ componentId, backendConfig = {}, extraConfig = {}, componentInfo }) => {
  try {
    // Get the component boundary config...
    const { mapComponentInfoToProps, ...componentConfig } = boundaryConfigMap[componentId] || boundaryConfigMap.default;
    const { isCritic: isCriticFromConfigMap, hasFallback, ownership: ownershipFromConfigMap } = componentConfig;

    // Get the critic config...
    const frontendHardcodedCriticComponents = criticComponents || [];
    const backendCriticComponents = backendConfig?.criticComponents || [];
    const criticComponentsList = [...frontendHardcodedCriticComponents, ...backendCriticComponents];
    const isCritic = isCriticFromConfigMap || criticComponentsList.includes(componentId);
    // Get the fallback and ownership config...
    const shouldRenderFallback = isCritic && hasFallback && componentInfo && mapComponentInfoToProps;
    const ownership = ownershipFromConfigMap || ownershipMap[componentId] || ownershipMap.default;

    return {
      ...componentConfig,
      ...extraConfig,
      componentId,
      isCritic,
      fallbackProps: shouldRenderFallback && mapComponentInfoToProps(componentInfo),
      FallbackComponent: shouldRenderFallback && fallbackComponentsMap[componentId],
      ownership,
    };
  } catch (error) {
    // Return the default config if something goes wrong
    return {
      ...DEFAULT_CONFIG,
      ...extraConfig,
      componentId,
    };
  }
};

export default getBoundaryConfig;
