import React from 'react';
import { node, shape, string } from 'prop-types';
import { feStatsdHelper } from '../utils/frontendStatsHelper';
import { LoggerFactory } from 'nordic/logger';

import {
  VPP_BOUNDARY_DATADOG_KEY_CASES,
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
} from '../services/frontend-statsd/config/allowed-keys';

const log = LoggerFactory('vpp_boundary_fe');

const logError = ({ error, info, componentId }) => {
  log.error(`Vpp-ErrorBoundary - ComponentBoundary`, { componentId, catch_context: 'client_side', error, info });
};

// Datadog config - ERROR BOUNDARY
const statsdConfig = {
  keyPrefix: VPP_BOUNDARY_DATADOG_KEY_PREFIX,
};

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const { vppBoundaryConfig } = this.props;
    const { app, deviceType, componentId, isCritic, hasFallback, ownership } = vppBoundaryConfig;

    feStatsdHelper({
      key: VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_COMPONENT,
      statsdConfig,
      additionalTags: {
        catch_context: 'client_side',
        referer_app: app,
        device_type: deviceType,
        component_id: componentId,
        is_critic: isCritic,
        has_fallback: hasFallback,
        ownership,
      },
      shouldLogMessage: true,
      additionalInfo: { error, info, componentId },
    });
    logError({ error, info, componentId });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // intentionally do nothing for now
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
  vppBoundaryConfig: shape({
    componentId: string,
  }),
};

export default ErrorBoundary;
