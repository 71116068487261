import { canUseDOM } from '../lib/dom-utils';
import APIService from '../services/api';
import { LoggerFactory } from 'nordic/logger';
import {
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
  VPP_BOUNDARY_DATADOG_KEY_CASES,
} from '../services/frontend-statsd/config/allowed-keys';

const log = LoggerFactory('vpp_boundary_fe');

const catchBoundary = ({ error, vppBoundaryConfig = {}, errorBoundaryComponentConfiguration = {} } = {}) => {
  const configuration = {
    ...vppBoundaryConfig, // This is the vpp-frontend configuration injected on the connect (Includes the vpp-backend global configuration)
    ...errorBoundaryComponentConfiguration, // This is the vpp-backend configuration that comes in the props of the component
  };
  const {
    app,
    deviceType,
    componentId,
    isCritic,
    hasFallback,
    fallbackProps,
    FallbackComponent,
    shouldSendMetrics,
    shouldSendLogs,
    ownership,
  } = configuration;

  const catchContext = canUseDOM ? 'client_side' : 'server_side';

  // Datadog
  if (shouldSendMetrics) {
    const metricsParams = {
      key: `${VPP_BOUNDARY_DATADOG_KEY_PREFIX}.${VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_TRY_CATCH}`,
      tags: {
        referer_app: app,
        device_type: deviceType,
        component_id: componentId,
        is_critic: isCritic,
        has_fallback: hasFallback,
        ownership,
        catch_context: catchContext,
      },
    };

    if (canUseDOM) {
      APIService.saveFrontendStatsd(metricsParams);
    } else {
      // Can't use statsd on client side, and so far this file is in the webpack bundle (so we use the api)
      // Cant' use the api on server side (need to use statsd here)
      // TODO: Use webpack-alias and require STATSD only SSR...
      // FrontendStatsdService.saveFrontendStatsd(metricsParams);
    }
  }

  // Kibana
  if (shouldSendLogs) {
    log.error(`Vpp-ErrorBoundary - TryCatch`, { component_id: componentId, catch_context: catchContext, error });
  }

  if (isCritic) {
    // is a critic component + have fallback props & component ... render a fallback:
    if (hasFallback && fallbackProps && FallbackComponent) {
      return <FallbackComponent {...fallbackProps} componentId={componentId} />;
    }
    // is a critic component + not have a fallback ... crash/interruptor
    throw new Error(`Catch on vpp-boundary. Error on ${componentId} component. Info: ${error}`);
  }

  // Non critic components render a fragment when catch
  return <></>;
};

export default catchBoundary;
