import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import Integrations from '../../../../components/integrations';

const FEATURE_NAME = 'third_party_second_section';

const mapStateToProps = ({ components: { [FEATURE_NAME]: resProps } }, ownProps) => ({
  ...resProps,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Integrations);
